import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const S = {
  Wrapper: styled(Dialog)`
    & .MuiPaper-root {
      display: flex;
      justify-content: center;
      padding: 16px;
    }
  `,
  IconButton: styled(IconButton)`
    &.MuiIconButton-root {
      position: absolute;
      right: 0;
      top: 0;
      color: ${p => p.theme.palette.grey[500]};
    }
  `,
};
type Props = DialogProps & {hideCloseButton?: boolean};

const CloseButton = ({ onClose }: { onClose: ComponentProps<typeof Dialog>['onClose'] }) => (
  <S.IconButton
    aria-label='close'
    onClick={onClose as ComponentProps<typeof IconButton>['onClick']}
  >
    <CloseIcon />
  </S.IconButton>
);

const SDModal: React.FC<Props> = ({ hideCloseButton, ...props }) => {
  return (
    <S.Wrapper data-testid='SDModal' {...props}>
      {!hideCloseButton && <CloseButton onClose={props.onClose} />}
      {props.children}
    </S.Wrapper>
  );
};

export default SDModal;
