import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, CircularProgress } from '@mui/material';
import { useCreateFeature } from './useCreateFeature';
import { SetState } from '../../utils/types/utility.types';
import { FeatureCollection } from '../../services/superdraft-api/features.endpoints';

const S = {
  Wrapper: styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: min-content;
  `,
};

const CreateFeature = ({
  close,
  selectFeature,
}: {
  close: () => void;
  selectFeature: SetState<FeatureCollection | null>;
}) => {
  const [name, setName] = useState('');
  const { createFeature, isLoading, error } = useCreateFeature({
    name: name.trim(),
    close,
    selectFeature,
  });

  // todo: the way we're currently testing errors, by throwing inside mirage rather
  //  than returning an error Response, requires us to get the message this way.
  //  once we get mirage to respond correctly, or work with real backend errors, we can fix it.
  const errorMessage = (error as { data: { message: string } })?.data?.message;

  return (
    <S.Wrapper data-testid='CreateFeature'>
      <Typography variant='h2' fontSize={24}>
        Create Feature
      </Typography>

      <Typography variant='body2'>
        Your feature will be created in the "test" environment, after which you can adjust settings
        in other environments.
      </Typography>

      <TextField
        sx={{ width: 300 }}
        label='Name'
        size='small'
        onChange={e => setName(e.target.value)}
        value={name}
      />

      {error && <Typography variant={'error'}>Error: {errorMessage || 'Unknown'}</Typography>}
      <Button onClick={createFeature} disabled={!name.trim().length}>
        {isLoading ? <CircularProgress size={30}/> : 'Save'}
      </Button>
    </S.Wrapper>
  );
};

export default CreateFeature;
