import { JSONBuilder } from './JSONBuilder';
import { Player } from './Player';
import { PlayerPropApiPayload } from '../services/superdraft-api/api-payload-types/playerProps.types';

export type PlayerPropJson = ReturnType<typeof PlayerProp.build>;

export class PlayerProp extends JSONBuilder<PlayerPropApiPayload> {
  static build(json: PlayerPropApiPayload) {
    const proposition = new PlayerProp(json);
    const { img, offer, outcomes } = proposition;
    const { startTimeUTC, _id: id, sportId, eventId, status } = json;
    return {
      player: Player.build(json.player),
      startTime: startTimeUTC,
      eventId,
      img,
      id,
      offer,
      outcomes,
      status,
      sportId: parseInt(sportId),
    };
  }

  // represents API's prop.question
  get offer() {
    const { json } = this;
    const choice = json.choices[0];
    return {
      name: choice.actor.winningRequirement[0].name,
    };
  }

  // represents API's prop.choices
  get outcomes() {
    return this.json.choices?.map(({ odds, type, id, ...choice }) => {
      const { value } = choice.actor.winningRequirement[0];
      return { type, value, odds, id };
    });
  }

  get img() {
    const { question } = this.json;
    return {
      url: question.img,
      alt: question.lblQuest,
    };
  }
}
export default PlayerProp;
