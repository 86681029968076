import { SDWebResponse } from '../web-api/webApi';
import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

// TODO: this was required to adapt to the responses in the legacy api.
//  we no longer use the legacy admin api. once we're not getting the list
//  of sports from the DFS api, we should be able to get rid of this completely.
export const createSDBaseQuery = <ResponseType extends SDWebResponse>(
  baseUrl: string,
  prepareHeaders: FetchBaseQueryArgs['prepareHeaders'],
): BaseQueryFn => {
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  });

  return async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error) return result;
    const {
      data: { data, errorCode, message },
    } = result as { data: ResponseType };

    if (errorCode) {
      return {
        error: {
          data: undefined, // required by typescript
          status: errorCode,
          error: message,
        },
      };
    }
    return { data };
  };
};
