import React from 'react';
import { NavGroup } from './NavGroup';
import styled from 'styled-components';
import { Drawer } from '@mui/material';
import { config } from '../routingConfig';
import SDLogo from '../../../assets/images/SuperDraft_horizontal_primary_black.svg';

const S = {
  Wrapper: styled(Drawer).attrs({ variant: 'permanent', anchor: 'left' })`
    .MuiDrawer-paper {
      width: 256px;
      height: 100%;
    }
  `,
  LogoWrapper: styled.div`
    height: 72px;
    padding: 32px 0 16px 16px;
    box-sizing: border-box;
  `,
  Logo: styled.img.attrs({ src: SDLogo })`
    width: 150px;
  `,
};

const SideNavigation = () => {
  return (
    <S.Wrapper data-testid='sideNavigation'>
      <S.LogoWrapper>
        <S.Logo />
      </S.LogoWrapper>
      {config.map(conf => (
        <NavGroup {...conf} key={conf.path} />
      ))}
    </S.Wrapper>
  );
};

export default SideNavigation;
