import React, { useMemo } from 'react';
import { DataGridPro, GridFilterModel } from '@mui/x-data-grid-pro';
import styled from 'styled-components';
import { AnyObject } from '../../utils/types/utility.types';
import { ReadonlyColumns } from '../DataPresentation/DataPresentation';
import { DataGridProps } from '@mui/x-data-grid';

const S = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
  `,
  Table: styled.div`
    flex-grow: 1;
    font-size: 14px;

    .MuiDataGrid-root {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    .MuiDataGrid-columnHeaders,
    .MuiDataGrid-root,
    .MuiDataGrid-main {
      border-radius: 0;
    }

    .MuiDataGrid-columnHeaders,
    .MuiDataGrid-row {
      box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
    }

    .MuiDataGrid-columnHeaderTitle {
      font-weight: 500;
    }

    .MuiDataGrid-cellContent {
      font-weight: 400;
    }

    .MuiDataGrid-columnSeparator {
      visibility: hidden;
    }
  `,
};

type Props = {
  columns: ReadonlyColumns;
  rows: AnyObject[];
  filterModel?: GridFilterModel;
} & Omit<DataGridProps, 'columns'>;

const DataTable: React.FC<Props> = ({ columns = [], rows = [], filterModel, ...props }) => {
  const memoizedColumns = useMemo(
    () =>
      columns.map(col => ({
        headerClassName: 'columnHeader',
        flex: 1, // all columns expand to fill width
        ...col, // but could override that behavior
      })),
    [columns],
  );

  return (
    <S.Wrapper>
      <S.Table>
        <DataGridPro
          autoHeight
          rows={rows}
          columns={memoizedColumns}
          filterModel={filterModel}
          {...props}
        />
      </S.Table>
    </S.Wrapper>
  );
};

export default DataTable;
