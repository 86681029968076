import { useAppDispatch } from '../../../configureStore';
import { NewUserSessionResponse } from '@superdraft/super-draft-common';
import { requestSession } from '../../../services/session';
import { useEffect } from 'react';
import { userSliceActions } from '../../slices/user.slice';
import '@aws-amplify/ui-react/styles.css';
import { CognitoUserAmplify } from '@aws-amplify/ui-react/node_modules/@aws-amplify/ui';

const { setUserInfoOnLogin } = userSliceActions;

export const useLogin = (amplifyUser: CognitoUserAmplify) => {
  const dispatch = useAppDispatch();

  const processLogin = async () => {
    const { user, session }: NewUserSessionResponse = await requestSession(amplifyUser);
    const {
      username,
      pool: { clientId },
    } = amplifyUser as CognitoUserAmplify & { pool: { clientId: string | number } };

    // When AWS Amplify logs in, it writes items to local storage
    const storageKey = `CognitoIdentityServiceProvider.${clientId}.${username}`;
    const amplifyToken = localStorage.getItem(`${storageKey}.idToken`);

    dispatch(
      setUserInfoOnLogin({
        amplifyToken,
        sessionToken: session.sessionToken,
        user,
      }),
    );
  };

  useEffect(() => {
    if (!amplifyUser) return;
    processLogin();
  }, [amplifyUser]);
};
