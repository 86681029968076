import { Authenticator as AmplifyAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { CognitoUserAmplify } from '@aws-amplify/ui-react/node_modules/@aws-amplify/ui';
import Application from '../Application';
import { useLogin } from './useLogin';

export type SignOutFn = ReturnType<typeof useAuthenticator>['signOut'];

type AuthenticatorProps = {
  signOut: SignOutFn;
  user: CognitoUserAmplify;
};

const AuthHandler = ({ signOut, user }: AuthenticatorProps) => {
  // use Cognito login info to log into the Superdraft Admin backend and get the actual user info
  useLogin(user);

  // render the application
  return !user ? null : <Application signOut={signOut} />;
};

// Render the AWS Amplify login UI
const AppAuthenticator = () => (
  <AmplifyAuthenticator hideSignUp>
    {({ signOut, user }) => <AuthHandler user={user} signOut={signOut} />}
  </AmplifyAuthenticator>
);

export default AppAuthenticator;
