import React from 'react';
import { AccordionDetails } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const S = {
  NavOptionWrapper: styled(Link)<{ selected: boolean }>`
    margin: 4px 8px;
    box-sizing: border-box;
    padding-left: 48px;
    height: 32px;
    background-color: ${ p => p.selected && p.theme.palette.primary['100'] };
    display: flex;
    align-items: center;

    .MuiAccordionDetails-root {
      padding: 0;
    }

    text-decoration: unset;
    color: unset; // allows "Title", as child of this, to inherit our *parent's* color

    :hover {
      background-color: ${ p => p.theme.palette.primary['100'] };
      color: inherit;
    }
  `,
  Title: styled(Typography)<{ selected: boolean }>`
    color: ${ p => (p.selected ? p.theme.palette.primary.main : 'inherit') };
  `,
};

export const NavOption: React.FC<{
  title: string;
  path: string;
  selected: boolean;
}> = ({ title, path, selected = false }) => (
  <S.NavOptionWrapper to={ path } selected={ selected }>
    <AccordionDetails>
      <Typography>{ title }</Typography>
    </AccordionDetails>
  </S.NavOptionWrapper>
);
