import { createApi } from '@reduxjs/toolkit/query/react';
import { AnyObject } from '../../utils/types/utility.types';
import { createSDBaseQuery } from '../superdraft-api/helpers';
import ApiConstants from '../superdraft-api/apiConstants';

const PLACEHOLDER_KEY = '5c2cd244785d95331652795747';
const API_KEY = 'cont*177';

const prepareHeaders = (headers: Headers) => {
  const headerInfo = {
    'API-KEY': API_KEY,
    'SESSION-KEY': PLACEHOLDER_KEY,
  };

  Object.entries(headerInfo).forEach(([k, v]) => {
    headers.set(k, v);
  });

  return headers;
};

export type SDWebResponse<Payload = AnyObject> = {
  data: Payload;
  errorCode: number;
  message: string;
};

// todo: get this dynamically based on environment etc.
export const webApiBaseUrl = 'https://api.staging.superdraft.io';

const webApi = createApi({
  reducerPath: 'webApi',
  baseQuery: createSDBaseQuery(webApiBaseUrl, prepareHeaders),
  endpoints: () => ({}),
});

export const { useGetPropsSportsQuery } = webApi.injectEndpoints({
  endpoints: build => ({
    getPropsSports: build.query<Record<number, string>, void>({
      query: ApiConstants.webApi.getPropsSports.queryFn as () => string,
      transformResponse: (data: {
        sports: { sportId: number; sName: string }[];
      }): Record<number, string> =>
        data.sports.reduce((obj, { sportId, sName: name }) => ({ ...obj, [sportId]: name }), {}),
    }),
  }),
});

export default webApi;
