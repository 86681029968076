import { coreUrl } from '../config';

export const sendRequest = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  path: string,
  token?: string | undefined,
  sessionToken?: string | undefined,
  body?: string,
): Promise<any> => {
  const url = encodeURI(`${coreUrl}${path}`);
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  if (sessionToken) {
    headers['session-token'] = sessionToken;
  }

  const requestOptions: RequestInit = {
    method,
    headers,
    mode: 'cors',
  };
  if (body) {
    requestOptions.body = body;
  }
  const response: Response = await fetch(url, requestOptions);

  if (!response.ok) {
    console.log('Api error');
  }

  const result = await response.json();
  return result;
};
