import adminApi from './adminApi';
import {
  Environment,
  GetFeaturesApiPayload,
  SetFeatureApiPayload,
} from './api-payload-types/features.types';
import ApiConstants from './apiConstants';
import Feature from '../../json-builder-models/Feature';

export type EnvironmentFeature = Omit<GetFeaturesApiPayload, 'toggleName'>;

export type FeatureCollection = {
  name: string;
  environments: Record<Environment, EnvironmentFeature>;
};

export const { useGetAllFeaturesQuery, useSetFeatureMutation, useDeleteFeatureMutation } =
  adminApi.injectEndpoints({
    endpoints: build => ({
      getAllFeatures: build.query<FeatureCollection[], void>({
        query: ApiConstants.features.getAllFeatures.queryFn as () => string,
        providesTags: ['feature'],
        transformResponse: (features: GetFeaturesApiPayload[]) => {
          const uniqueNames = [...new Set(features.map(f => f.toggleName))];
          const featureCollections = uniqueNames.map(name =>
            features.filter(f => f.toggleName === name),
          );
          return featureCollections.map(Feature.build);
        },
      }),
      setFeature: build.mutation<FeatureCollection, { name: string; feature: EnvironmentFeature }>({
        query: ({ name, feature }) => {
          const body: SetFeatureApiPayload = {
            ownerEmail: feature.ownerEmail,
            globallyEnabled: feature.globallyEnabled,
            enabledIds: feature.enabledIds,
          };
          return {
            url: ApiConstants.features.setFeature.queryFn(name, feature.environment),
            method: ApiConstants.features.setFeature.method,
            body,
          };
        },
        transformResponse: (data: GetFeaturesApiPayload) => Feature.build([data]),
        invalidatesTags: ['feature'],
      }),
      deleteFeature: build.mutation<void, { name: string; feature: EnvironmentFeature }>({
        query: ({ name, feature }) => ({
          url: ApiConstants.features.deleteFeature.queryFn(name, feature.environment),
          method: ApiConstants.features.deleteFeature.method,
        }),
        invalidatesTags: ['feature'],
      }),
    }),
  });
