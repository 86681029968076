import { AnyObject } from '../types/utility.types';
import { useEffect } from 'react';

export function useUpdateSelectedOnRefresh<T extends AnyObject>({
  collection,
  selected,
  setSelected,
  compareKey,
}: {
  collection: T[] | undefined;
  selected: T | null;
  setSelected: (item: T) => void;
  compareKey: keyof T;
}) {
  const selectedEntity = collection?.find(
    item => selected && item[compareKey] === selected[compareKey],
  );
  
  useEffect(() => {
    if (selectedEntity) {
      setSelected(selectedEntity);
    }
  }, [selectedEntity]);
}
