import { Environment } from '../../services/superdraft-api/api-payload-types/features.types';
import { FormControlLabel, Switch } from '@mui/material';

export const GloballyEnabledSwitch = ({
  globallyEnabled,
  environment,
  name,
  onChange,
}: {
  name: string;
  environment: Environment;
  globallyEnabled: boolean;
  onChange: () => void;
}) => (
  <FormControlLabel
    label='Globally enabled'
    control={
      <Switch
        data-testid={`globallyEnabled-${name}-${environment}`}
        checked={globallyEnabled}
        onChange={onChange}
      />
    }
  />
);
