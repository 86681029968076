import {
  EnvironmentFeature,
  useDeleteFeatureMutation,
  useSetFeatureMutation,
} from '../../services/superdraft-api/features.endpoints';
import { useState } from 'react';
import useStateCallback from '../../utils/hooks/useStateCallback';
import { useAppSelector } from '../../../configureStore';
import { getUserEmail } from '../../slices/user.slice';

const useSingleEnvironmentFeature = ({
  name,
  feature,
}: {
  name: string;
  feature: EnvironmentFeature;
}) => {
  const userEmail = useAppSelector(getUserEmail);
  const ownerEmail = feature.ownerEmail || userEmail;
  
  const [postFeature, { error: saveError, isLoading: saveLoading }] = useSetFeatureMutation();
  const [_deleteFeature, { error: deleteError, isLoading: deleteLoading }] =
    useDeleteFeatureMutation();
  
  const [globallyEnabled, setGloballyEnabled] = useStateCallback(feature.globallyEnabled);
  const [enabledIdsStr, setEnabledIdsStr] = useState(feature.enabledIds.join(' '));
  
  const toggleGloballyEnabled = () => {
    setGloballyEnabled(!globallyEnabled, (newState: boolean) =>
      postFeature({
        name,
        feature: { ...feature, ownerEmail, globallyEnabled: newState },
      }),
    );
  };
  
  const saveEnabledIds = () =>
    postFeature({
      name,
      feature: { ...feature, ownerEmail, enabledIds: enabledIdsStr.split(' ') },
    });
  
  const deleteFeature = () => _deleteFeature({ name, feature });
  
  return {
    globallyEnabled,
    enabledIdsStr,
    toggleGloballyEnabled,
    setEnabledIdsStr,
    saveEnabledIds,
    saveLoading,
    saveError,
    deleteError,
    deleteLoading,
    deleteFeature,
  };
};

export default useSingleEnvironmentFeature;
