import { useMemo } from 'react';
import { FilterOption } from '../../types/dataPresentation.types';
import { AnyObject } from '../../utils/types/utility.types';
import { GridColumns } from '@mui/x-data-grid';

// RowType is the type of the rows themselves, whereas the return type here is an object
// whose values are the possible values of those rows, which we will say is FilterOption.
// We'll have to think about the idea that "doesn't this mean that either RowType should
// extend Record<string, DataOption>, or that this should return <RowType extends AnyObject>?"
// The latter causes errors in the calling component, which seems correct.
// Even though a row may be able to have an object as a value for a field,
// we wouldn't want to ever deal with those in a dropdown or multifilter.
export const getUniqueValuesForFilters = <RowType extends AnyObject,
  Columns extends Readonly<GridColumns>,
  >({
  rows,
  columnConfig,
  filters = [],
}: {
  rows: RowType[];
  columnConfig: readonly {
    field: Columns[number]['field'];
    valueGetter?: ({ row }: { row: RowType }) => string;
  }[];
  filters?: readonly { field: Columns[number]['field'] }[][];
}): Record<Columns[number]['field'], FilterOption[]> => {
  const fields = filters.map(list => list?.map(f => f.field)).flat();
  const uniqueValuesTable = fields.reduce((obj, field) => {
    const allValuesForField = rows.map(row => {
      const column = columnConfig.find(c => c.field === field) as typeof columnConfig[number];
      
      // we only need a valueGetter if we're not directly getting the value from that property of the row.
      if (!column.valueGetter) return row[field as keyof RowType];
      return column.valueGetter({ row });
    });
    const uniqueValuesForField = [...new Set(allValuesForField)];
    const valuesAsOptions = uniqueValuesForField.map(value => ({
      value,
      label: value,
    }));
    
    return {
      ...obj,
      [field]: valuesAsOptions,
    };
  }, {});
  
  return uniqueValuesTable as Record<Columns[number]['field'], FilterOption[]>;
};

export const useUniqueValuesForFilters: typeof getUniqueValuesForFilters = ({
  rows,
  columnConfig,
  filters = [],
}) => {
  return useMemo(
    () =>
      getUniqueValuesForFilters({ rows, columnConfig, filters, }),
    [rows, columnConfig, filters.flat().length],
  );
};
