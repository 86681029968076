import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { dataFilters } from './app/slices/dataFiltering.slice';
import { user } from './app/slices/user.slice';
import adminApi from './app/services/superdraft-api/adminApi';
import { AnyObject } from './app/utils/types/utility.types';
import { setupListeners } from '@reduxjs/toolkit/query';
import webApi from './app/services/web-api/webApi';

// we can't use RootState as the type because then RootState (below)
// would circularly reference itself
export const createStore = (initialState?: AnyObject) => {
  const store = configureStore({
    reducer: combineReducers({
      dataFilters,
      user,
      [adminApi.reducerPath]: adminApi.reducer,
      [webApi.reducerPath]: webApi.reducer,
    }),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat([adminApi.middleware, webApi.middleware]),
    preloadedState: initialState,
  });
  // required for refetchOnFocus/refetchOnReconnect behaviors
  setupListeners(store.dispatch);
  return store;
};

type StoreType = ReturnType<typeof createStore>;
export type RootState = ReturnType<StoreType['getState']>;
export type AppDispatch = StoreType['dispatch'];

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
