import styled from 'styled-components';
import { useGetAllPropsQuery } from '../../services/superdraft-api/playerProps.endpoints';
import Typography from '@mui/material/Typography';
import DataPresentation from '../../components/DataPresentation';
import { PlayerPropJson } from '../../json-builder-models/PlayerProp';
import { capitalize } from '@mui/material';
import { useGetPropsSportsQuery } from '../../services/web-api/webApi';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
};

type GetterArg = { row: PlayerPropJson };

// no, there is *no* other way to type this. the function can't return GridColumns: TS error.
// you can't cast each column as GridColumns<PlayerPropJson>[number]: loses type safety for filters
const playerPropsColumns = (sportsTable: Record<number, string>) =>
  [
    {
      field: 'sportId',
      headerName: 'Sport',
      flex: 0,
      valueGetter: ({ row: { sportId } }: GetterArg) => sportsTable[sportId],
    },
    {
      field: 'playerName',
      headerName: 'Player',
      valueGetter: ({ row: { player } }: GetterArg) => `${player.firstName} ${player.lastName}`,
    },
    {
      field: 'line',
      headerName: 'Line',
      valueGetter: ({ row: { offer, outcomes } }: GetterArg) =>
        `${outcomes[0].value} ${offer.name}`,
    },
    // { field: 'boost', headerName: 'Boost' },
    // { field: 'totalCash', headerName: 'Total Cash' },
    // { field: 'totalUsers', headerName: 'Total Users' },
    // { field: 'overCash', headerName: 'Over Cash' },
    // { field: 'underCash', headerName: 'Under Cash' },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter: ({ row: { status } }: GetterArg) => capitalize(status),
    },
  ] as const;

const PlayerPropsPage = () => {
  const { data: playerProps, isLoading, isError, error } = useGetAllPropsQuery();
  const { data: sports } = useGetPropsSportsQuery();

  return (
    <S.Wrapper data-testid='PlayerPropsPage'>
      <Typography variant='h1'>Player Props</Typography>

      {isLoading && <Typography>Loading</Typography>}
      {isError && <Typography> Error: ${JSON.stringify(error)}</Typography>}
      {playerProps && sports && (
        <DataPresentation
          tableId={'playerProps'}
          columns={playerPropsColumns(sports)}
          rows={playerProps}
          buttonGroupFilters={[{ field: 'status', title: 'Filter by status' }]}
          searchKey={'playerName'}
          dropdownFilters={[
            {
              field: 'sportId',
              allString: 'All Sports',
            },
          ]}
        />
      )}
    </S.Wrapper>
  );
};

export default PlayerPropsPage;
