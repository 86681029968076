import { createApi } from '@reduxjs/toolkit/query/react';
import { AnyObject } from '../../utils/types/utility.types';
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { RootState } from '../../../configureStore';
import ApiConstants from './apiConstants';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { coreUrl } from '../../../config';

export type PrepareHeadersFn = FetchBaseQueryArgs['prepareHeaders'];

const prepareHeaders: PrepareHeadersFn = (headers: Headers, { getState }) => {
  const { amplifyToken, sessionToken } = (getState() as RootState).user;

  const headerInfo = {
    Authorization: `Bearer ${amplifyToken}`,
    'session-token': sessionToken,
  };

  Object.entries(headerInfo).forEach(([k, v]) => {
    headers.set(k, v);
  });

  return headers;
};

export type SDAdminResponse<Payload = AnyObject> = {
  admin: { adminRole: AnyObject; lastUpdatedAt: string; sessionExpires: string };
  data: Payload;
  errorCode: number;
  message: string;
};

const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: coreUrl,
    prepareHeaders,
  }),
  tagTypes: ['feature'],
  endpoints: () => ({}),
});

export const { useGetUserQuery } = adminApi.injectEndpoints({
  endpoints: build => ({
    getUser: build.query<unknown, void>({
      query: ApiConstants.user.getUser.queryFn,
    }),
  }),
});

export default adminApi;
