import DataTable from '../DataTable/DataTable';
import styled from 'styled-components';
import SearchInput from '../SearchInput';
import FilterSelect from '../FilterSelect';
import type { SingleFilterConfig } from '../FilterSelect/FilterSelect';
import FilterButtonGroup, { MultiFilterConfig } from '../FilterButtonGroup/FilterButtonGroup';
import { useUniqueValuesForFilters } from './useUniqueValuesForFilters';
import { useDataFiltering } from '../../slices/useDataFiltering';
import { AnyObject } from '../../utils/types/utility.types';
import useDataPresentationFilterModels from './useDataPresentationFilterModels';
import { DataGridProps } from '@mui/x-data-grid';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  Header: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
  `,
};

type BasedOnData<BaseConfig, Field extends string> = { field: Field } & Omit<BaseConfig, 'options'>;

export type ReadonlyColumns = readonly { field: string }[];

type Props<Columns extends ReadonlyColumns> = {
  // The id to identify this table among the many in the application.
  // The table filter settings are stored in Redux under state.dataFilters[tableId]
  tableId: string;
  columns: Columns;
  rows: AnyObject[];
  searchKey?: Columns[number]['field'];
  dropdownFilters?: BasedOnData<SingleFilterConfig, Columns[number]['field']>[];
  buttonGroupFilters?: BasedOnData<MultiFilterConfig, Columns[number]['field']>[];
} & Omit<DataGridProps, 'columns'>;

const DataPresentation = <Columns extends ReadonlyColumns>({
  tableId,
  rows,
  columns,
  searchKey,
  dropdownFilters = [],
  buttonGroupFilters = [],
  ...props
}: Props<Columns>) => {
  const { searchText, setSearchText, setSingleFilter, setMultiFilter } = useDataFiltering({
    tableId,
    searchKey,
    singleFilters: dropdownFilters,
    multiFilters: buttonGroupFilters,
  });

  const uniqueValues = useUniqueValuesForFilters({
    rows,
    columnConfig: columns,
    filters: [dropdownFilters, buttonGroupFilters],
  });
  const filterModelItems = useDataPresentationFilterModels({ tableId });

  return (
    <S.Wrapper data-testid='dataPresentation'>
      <S.Header>
        {!!searchKey && (
          <SearchInput searchKey={searchKey} value={searchText} onChange={setSearchText} />
        )}

        {dropdownFilters.map(({ field, ...config }) => (
          <FilterSelect
            key={`${field}`}
            options={uniqueValues[field]}
            onChange={value => setSingleFilter({ field, value })}
            {...config}
          />
        ))}

        {buttonGroupFilters.map(({ field, ...config }) => (
          <FilterButtonGroup
            key={`${field}`}
            options={uniqueValues[field]}
            onChange={values => setMultiFilter({ field, values })}
            {...config}
          />
        ))}
      </S.Header>

      <DataTable
        columns={columns}
        rows={rows}
        filterModel={{ items: filterModelItems }}
        {...props}
      />
    </S.Wrapper>
  );
};

export default DataPresentation;

/*
 * Type enforcement of columns and filters can be easy to accidentally break with refactoring,
 * and can't easily be tested in our Jest files. Using @ts-expect-error here will alert us if
 * the type checking has been broken.
 * */
const testColumns = [{ field: 'test' }] as const;
const testSearchKey = 'invalid';
const testDropdownFilters = [{ field: 'invalid', allString: '' }];
const testButtonGroupFilters = [{ field: 'invalid', title: '' }];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TypeTestComponent = (
  <DataPresentation
    tableId={'test'}
    columns={testColumns}
    rows={[]}
    // @ts-expect-error - ensure columns and filters are typed correctly
    searchKey={testSearchKey}
    // @ts-expect-error - ensure columns and filters are typed correctly
    dropdownFilters={testDropdownFilters}
    // @ts-expect-error - ensure columns and filters are typed correctly
    buttonGroupFilters={testButtonGroupFilters}
  />
);
