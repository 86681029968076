import {
  FeatureCollection,
  useGetAllFeaturesQuery,
} from '../../services/superdraft-api/features.endpoints';
import { useState } from 'react';
import { useUpdateSelectedOnRefresh } from '../../utils/hooks/useUpdateSelectedOnRefresh';
import { Environment } from '../../services/superdraft-api/api-payload-types/features.types';
import { capitalize } from '@mui/material';
import useBoolean from '../../utils/hooks/useBoolean';

const getColumnForEnvironment = (env: Environment) => ({
  field: `${env}Status`,
  headerName: capitalize(env),
  renderCell: ({ row }: { row: FeatureCollection }) => {
    const environmentObject = row.environments[env];
    const disabledStr = 'Off';
    if (!environmentObject) return disabledStr;
    if (environmentObject.globallyEnabled) return 'Global';
    if (environmentObject.enabledIds.length) return 'Partial';
    return disabledStr;
  },
});

const featuresColumns = [
  {
    field: 'name',
    headerName: 'Name',
  },
  ...Object.values(Environment).map(getColumnForEnvironment),
];

const useFeatureManagementPage = () => {
  const { data: featureCollections, isLoading, isError, error } = useGetAllFeaturesQuery();
  const [selectedFeatureCollection, setSelectedFeatureCollection] =
    useState<FeatureCollection | null>(null);

  const [creating, showCreate, hideCreate] = useBoolean(false);

  useUpdateSelectedOnRefresh({
    collection: featureCollections,
    selected: selectedFeatureCollection,
    setSelected: setSelectedFeatureCollection,
    compareKey: 'name',
  });

  const clearSelection = () => setSelectedFeatureCollection(null);

  return {
    featuresColumns,
    featureCollections,
    isLoading,
    isError,
    error,
    setSelectedFeatureCollection,
    selectedFeatureCollection,
    clearSelection,
    creating,
    showCreate,
    hideCreate,
  };
};

export default useFeatureManagementPage;
