import styled from 'styled-components';
import { useGetUserQuery } from '../../services/superdraft-api/adminApi';
import { useAppSelector } from '../../../configureStore';
import { getUserId } from '../../slices/user.slice';

const S = {
  Wrapper: styled.div``,
};

// todo: this is currently proof-of-concept to make sure we're querying the core api correctly.
const UserProfilePage = () => {
  const userId = useAppSelector(getUserId);
  const userResult = useGetUserQuery();

  return (
    <S.Wrapper data-testid='UserProfilePage'>
      UserProfilePage {userId} {userResult.status}
    </S.Wrapper>
  );
};

export default UserProfilePage;
