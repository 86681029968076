import awsConfig from './awsconfig.json';

const currentEnv = process.env.REACT_APP_ENVIRONMENT as keyof typeof backendCoreApiUrl || 'staging';

export const getAwsEnvironmentConfig = () => awsConfig[currentEnv === 'production' ? 'production' : 'sandbox'];

const backendCoreApiUrl = {
  test: 'https://test.core.superdraft.io/api',
  staging: 'https://staging.core.superdraft.io/api',
  production: 'https://core.superdraftcasino.net/api',
  local: 'http://localhost:8080',
};

export const coreUrl = backendCoreApiUrl[currentEnv]

