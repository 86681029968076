import styled from 'styled-components';
import {
  EnvironmentFeature,
  FeatureCollection,
} from '../../services/superdraft-api/features.endpoints';
import Typography from '@mui/material/Typography';
import FeatureEnvironmentDetail from '../FeatureEnvironmentDetail';
import { Environment } from '../../services/superdraft-api/api-payload-types/features.types';

const SPACING = 24;
const S = {
  Wrapper: styled.div`
    margin: ${SPACING}px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 1200px;
  `,
  Row: styled.div`
    display: flex;
    gap: ${SPACING}px;
    flex-wrap: wrap;
  `,
};

export const newEnvFeature = (envName: Environment): EnvironmentFeature => ({
  globallyEnabled: false,
  enabledIds: [],
  environment: envName,
  ownerEmail: '',
  uuid: '',
});

const FeatureDetail = ({ featureCollection }: { featureCollection: FeatureCollection }) => {
  return (
    <S.Wrapper data-testid='FeatureDetail'>
      <Typography variant='h1'>Feature: {featureCollection.name}</Typography>

      <S.Row>
        {Object.values(Environment).map(envName => {
          const existingEnvFeature = featureCollection.environments[envName];

          return (
            <FeatureEnvironmentDetail
              name={featureCollection.name}
              feature={existingEnvFeature || newEnvFeature(envName)}
              key={`${featureCollection.name}-${envName}`}
            />
          );
        })}
      </S.Row>
    </S.Wrapper>
  );
};

export default FeatureDetail;
