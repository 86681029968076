import { useDataFiltering } from '../../slices/useDataFiltering';
import { GridFilterItem } from '@mui/x-data-grid';
import { MultiFilterState, SingleFilterState } from '../../slices/dataFiltering.slice';

const useDataPresentationFilterModels = ({ tableId }: { tableId: string }): GridFilterItem[] => {
  const { searchKey, searchText, singleFilters, multiFilters } = useDataFiltering({ tableId });
  
  const sources: Array<SingleFilterState | MultiFilterState> = [
    (searchKey && { field: searchKey, value: searchText }) as SingleFilterState,
    ...singleFilters,
    ...multiFilters,
  ];
  
  return sources.filter(Boolean).reduce((arr, filter) => {
    const isSingleFilter = 'value' in filter;
    return [
      ...arr,
      {
        id: filter.field,
        columnField: filter.field,
        operatorValue: isSingleFilter ? 'contains' : 'isAnyOf',
        value: isSingleFilter ? (filter.value === 'all' ? '' : filter.value) : filter.values,
      },
    ];
  }, [] as GridFilterItem[]);
};

export default useDataPresentationFilterModels;
