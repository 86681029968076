import React from 'react';
import styled from 'styled-components';
import SideNavigation from '../../navigation/SideNavigation';
import { AppRouter } from '../../navigation/AppRouter';
import { useAppSelector } from '../../../configureStore';
import { getUserDisplayName } from '../../slices/user.slice';
import { SignOutFn } from '../AppAuthenticator/AppAuthenticator';

const S = {
  Wrapper: styled.div`
    display: flex;
    height: 100%;

    .MuiDrawer-root > .MuiPaper-root {
      position: unset;
    }
  `,
  OutletWrapper: styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    flex: 1;
  `,
};

type Props = {
  signOut: SignOutFn;
};

const SignOutButton = ({ signOut }: { signOut: SignOutFn }) => {
  const username = useAppSelector(getUserDisplayName);
  return (
    <a style={{ marginTop: 'auto', cursor: 'pointer' }} onClick={signOut}>
      Sign out of {username}
    </a>
  );
};

const Application: React.FC<Props> = ({ signOut }) => {
  const username = useAppSelector(getUserDisplayName);
  return (
    <S.Wrapper>
      <SideNavigation />
      <S.OutletWrapper>
        <div>
          {/* just here to position the sign-out placeholder */}
          {username && <AppRouter />}
        </div>
        <SignOutButton signOut={signOut} />
      </S.OutletWrapper>
    </S.Wrapper>
  );
};

export default Application;
