import { useState } from 'react';

export default function useMultiSelect<T>(values?: T[], onChange?: (val: T[]) => void) {
  const [currentValues, setCurrentValues] = useState(values || []);
  
  const onClickOption = (val: T) => {
    let newValues: T[];
    
    if (currentValues.includes(val)) {
      newValues = currentValues.filter(v => v !== val);
    } else {
      newValues = [...currentValues, val];
    }
    setCurrentValues(newValues);
    onChange?.call({}, newValues);
  };
  
  return {
    onClickOption,
    currentValues
  };
}
