import { CognitoUserAmplify } from '@aws-amplify/ui-react/node_modules/@aws-amplify/ui';
import { NewUserSessionRequest, NewUserSessionResponse } from '@superdraft/super-draft-common';
import { sendRequest } from './sendRequest';
import ApiConstants from '../app/services/superdraft-api/apiConstants';

export const requestSession = async (cognitoUserAuthRespose: CognitoUserAmplify): Promise<NewUserSessionResponse> => {
  const {
    signInUserSession: { idToken: accessToken },
    attributes: { birthdate, email, preferred_username: userName },
  } = cognitoUserAuthRespose as any;
  const dob = new Date(birthdate);

  const request: NewUserSessionRequest = {
    accessToken,
    type: 'external', // TODO: Add admin type or bypass user session
    deviceType: 0, // 0 = Web
    dob,
    email,
    userName,
  };

  const { jwtToken } = accessToken;
  const body = JSON.stringify(request);

  const { queryFn } = ApiConstants.user.postSession;
  const result = sendRequest('POST', queryFn(), jwtToken, undefined, body);
  return result;
};
