import { JSONBuilder } from './JSONBuilder';
import { GetFeaturesApiPayload } from '../services/superdraft-api/api-payload-types/features.types';
import { FeatureCollection } from '../services/superdraft-api/features.endpoints';

class Feature extends JSONBuilder {
  static build(featuresForAllEnvironments: GetFeaturesApiPayload[]): FeatureCollection {
    return {
      name: featuresForAllEnvironments[0].toggleName,
      environments: featuresForAllEnvironments.reduce(
        (obj, { toggleName, ...envObj }) => ({
          ...obj,
          [envObj.environment]: envObj,
        }),
        {} as FeatureCollection['environments'],
      ),
    };
  }
}

export default Feature;
