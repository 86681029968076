import { Route, Routes } from 'react-router-dom';
import { config } from './routingConfig';
import Typography from '@mui/material/Typography';

export const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Typography>Root route</Typography>} />
        {config.map(conf => (
          /* Section */
          <Route path={`${conf.path}/*`} key={conf.path}>
            {conf.config?.map(inner => (
              /* Page */
              <Route
                path={`${inner.path}`}
                key={inner.path}
                element={<h1 data-testid={inner.path}>{inner.element || inner.title}</h1>}
              />
            ))}
            )
          </Route>
        ))}
      </Routes>
    </>
  );
};
