import adminApi from './adminApi';
import ApiConstants from './apiConstants';
import { PlayerProp, PlayerPropJson } from '../../json-builder-models/PlayerProp';
import { PlayerPropApiPayload } from './api-payload-types/playerProps.types';

export const { useGetAllPropsQuery } = adminApi.injectEndpoints({
  endpoints: build => ({
    getAllProps: build.query<PlayerPropJson[], void>({
      query: ApiConstants.playerProps.getAllProps.queryFn as () => string,
      transformResponse: (data: PlayerPropApiPayload[]): PlayerPropJson[] =>
        data.map(PlayerProp.build),
    }),
  }),
});
