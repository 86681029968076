import { Environment } from './api-payload-types/features.types';

export type QueryFn = (() => string) | ((...args: any[]) => string);

export type ApiEndpoint = {
  method: 'get' | 'post' | 'put' | 'delete';
  queryFn: QueryFn;
  /**
   * For an endpoint whose queryFn takes arguments, the names of those arguments should be provided
   * as an array in the `params` member. This allows our `mockEndpoint` helper to work with these endpoints
   * by creating a url with dynamic segments.
   */
  params?: readonly string[];
};

const ApiConstants = {
  user: {
    getUser: {
      method: 'get',
      queryFn: () => `/user/user`,
    },
    getUserBalance: {
      method: 'get',
      queryFn: () => '/user/userBalance',
    },
    postSession: {
      method: 'post',
      queryFn: () => '/session', // todo: get the session using RTK Query
    },
  },
  admin: {
    findUsers: {
      method: 'get',
      queryFn: (keyword: string) => `/admin/user/search/${keyword}`,
      params: ['keyword'],
    },
    getAdminRole: {
      method: 'get',
      queryFn: () => '/admin/adminRole',
    },
    getUserInfo: {
      method: 'get',
      queryFn: (userId: string) => `/admin/user/userId/${userId}`,
      params: ['userId'],
    },
    getUserBalance: {
      method: 'get',
      queryFn: (userId: string) => `/admin/userBalance/userId/${userId}`,
      params: ['userId'],
    },
  },
  features: {
    getAllFeatures: {
      method: 'get',
      queryFn: () => '/admin/features',
    },
    setFeature: {
      method: 'put',
      queryFn: (name: string, environment: Environment) =>
        `/admin/features/${name}/environment/${environment}`,
      params: ['name', 'environment'],
    },
    deleteFeature: {
      method: 'delete',
      queryFn: (name: string, environment: Environment) =>
        `/admin/features/${name}/environment/${environment}`,
      params: ['name', 'environment'],
    },
  },
  playerProps: {
    getAllProps: {
      method: 'get',
      queryFn: () => '/prop/all',
    },
  },
  webApi: {
    getPropsSports: {
      method: 'get',
      queryFn: () => '/prop/active-fantasy',
    },
  },
} as const;

export default ApiConstants;

/**
 * In order for our uses of ApiConstants to be type-safe, it can't have a type annotation.
 * Without a type annotation on ApiConstants, each endpoint needs to have its queryFn parameters typed,
 * or you can type the endpoint object with `as ApiEndpoint`
 */
// @ts-expect-error check type-checking for api constants
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const error = ApiConstants.invalid;
// @ts-expect-error check type-checking for api constants
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const error2 = ApiConstants.features.invalid;
// @ts-expect-error check type-checking for api constants
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const error3 = ApiConstants.features.setFeature.invalid;
