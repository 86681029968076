import React from 'react';
import styled from 'styled-components';
import { InputAdornment, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

type Props = {
  searchKey: string;
  // For extending via styled-components
  className?: string;
  value: string;
  onChange: (str: string) => void;
};

const S = {
  Input: styled(OutlinedInput)`
    height: 38px;
    width: 400px;

    &.MuiOutlinedInput-root {
      padding-right: 12px;
    }
  `,
};

const SearchInput: React.FC<Props> = ({ className, value = '', onChange }) => {
  return (
    <S.Input
      value={value}
      onChange={e => onChange?.call({}, e.target.value)}
      className={className}
      placeholder='Search'
      endAdornment={
        <InputAdornment position={'end'}>
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

export default SearchInput;
