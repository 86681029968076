export enum Environment {
  test = 'test',
  staging = 'staging',
  production = 'production',
}

export interface GetFeaturesApiPayload {
  ownerEmail: string;
  environment: Environment;
  toggleName: string;
  globallyEnabled: boolean;
  uuid: string;
  enabledIds: string[];
}

export type SetFeatureApiPayload = Pick<
  GetFeaturesApiPayload,
  'enabledIds' | 'globallyEnabled' | 'ownerEmail'
>;
