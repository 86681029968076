import styled from 'styled-components';
import useSingleEnvironmentFeature from './useSingleEnvironmentFeature';
import Typography from '@mui/material/Typography';
import { EnvironmentFeature } from '../../services/superdraft-api/features.endpoints';
import { Button, FormGroup, TextareaAutosize } from '@mui/material';
import { GloballyEnabledSwitch } from './GloballyEnabledSwitch';

const S = {
  Wrapper: styled(FormGroup)`
    gap: 16px;
    flex: 1;
    max-width: 600px;
    justify-content: space-between;
  `,
  FeatureName: styled(Typography)`
    &.MuiTypography-root {
      text-transform: capitalize;
      font-weight: 700;
    }
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
  `,
  Col: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  IdsWrapper: styled.div`
    width: 100%;
  `,
  BottomRow: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
};

type Props = { name: string; feature: EnvironmentFeature };

export const FeatureEnvironmentDetail = ({ name, feature }: Props) => {
  const {
    toggleGloballyEnabled,
    globallyEnabled,
    enabledIdsStr,
    setEnabledIdsStr,
    saveEnabledIds,
    saveError,
  } = useSingleEnvironmentFeature({
    name,
    feature,
  });
  const errorMessage = (saveError as { data: { message: string } })?.data?.message;

  return (
    <S.Wrapper data-testid={`FeatureEnvironmentDetail-${name}-${feature.environment}`}>
      <div>
        <S.Row>
          <S.FeatureName>{feature.environment}</S.FeatureName>
          <GloballyEnabledSwitch
            name={name}
            environment={feature.environment}
            globallyEnabled={globallyEnabled}
            onChange={toggleGloballyEnabled}
          />
        </S.Row>
        {feature.ownerEmail && (
          <Typography variant={'body2'}>Owner: {feature.ownerEmail}</Typography>
        )}
      </div>

      <S.Col>
        <Typography>Enter user ids for the feature, separated by spaces:</Typography>

        <TextareaAutosize
          data-testid={`enabledIds-${name}-${feature.environment}`}
          placeholder='Enabled ids'
          minRows={6}
          value={enabledIdsStr}
          onChange={e => setEnabledIdsStr(e.target.value)}
        />

        <S.BottomRow>
          <div>
            {saveError && (
              <Typography variant='error'>Error: {errorMessage || 'unknown'}</Typography>
            )}
          </div>

          <Button onClick={saveEnabledIds}>Save</Button>
        </S.BottomRow>
      </S.Col>
    </S.Wrapper>
  );
};

export default FeatureEnvironmentDetail;
