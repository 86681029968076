import styled from 'styled-components';
import { Accordion, AccordionSummary } from '@mui/material';
import { useLocation } from 'react-router-dom';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { useToggle } from '../../utils/hooks/useBoolean';
import { NavOption } from './NavOption';
import { NavConfig } from '../routingConfig';

export const S = {
  AccordionWrapper: styled.div<{ selected: boolean }>`
    .MuiAccordion-root {
      box-shadow: none;

      // :first and :last are required to override Mui's specificity
      :first-of-type,
      :last-of-type {
        margin-bottom: 0;
        border-radius: 0;
      }
    }

    p.MuiTypography-root {
      font-size: 14px;
      font-weight: 500;
      border-bottom: none;
    }

    // selected state for titles and pages
    p.MuiTypography-root,
    svg {
      opacity: ${ p => !p.selected && 0.6 };
    }

    // hover state for titles
    & .MuiAccordionSummary-root:hover {
      p.MuiTypography-root,
      svg {
        opacity: 1;
      }
    }
  `,
  NavGroupWrapper: styled.div`
    .MuiAccordionSummary-root {
      height: 48px;

      &.Mui-expanded {
        min-height: unset;
      }
    }

    .MuiAccordionSummary-content {
      display: flex;
      gap: 18px;
      align-items: center;

      &.Mui-expanded {
        margin: 0;
      }
    }
  `,
};

export const NavGroup: React.FC<{
  title: string;
  icon?: React.ReactElement;
  config?: NavConfig;
  path: string | string[];
}> = ({ title, icon, config, path }) => {
  const { pathname } = useLocation();
  const currentGroup = pathname.split('/')[1];
  const [expanded, toggleExpanded] = useToggle(path === currentGroup);
  
  return (
    <S.AccordionWrapper selected={ path === currentGroup }>
      <Accordion expanded={ expanded } onChange={ toggleExpanded }>
        <S.NavGroupWrapper>
          <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
            { icon }
            <Typography>{ title }</Typography>
          </AccordionSummary>
        </S.NavGroupWrapper>
        
        { config?.map(({ title, path: childPath }) => {
          const fullPath = [[path].flat(), childPath].join('/');
          return (
            <NavOption
              title={ title }
              path={ fullPath }
              selected={ fullPath === pathname.slice(1) } // pathname includes leading slash
              key={ childPath }
            />
          );
        }) }
      </Accordion>
    </S.AccordionWrapper>
  );
};
