import { useState } from "react";

/**
 * A hook for managing a boolean state value.
 *
 * @param {boolean} initialValue
 * The initial value
 *
 * @param {boolean} withToggle
 * When true, second element of return is a toggle function, so the hook could
 * be used `[value, toggleValue]`, with the remaining returned elements ignored.
 * Similarly, when false, the toggle function can be ignored: `[val, setValTrue, setValFalse]`

 * @returns {[boolean,(function(): void),(function(): void),(function(): void)]}
 * The state value and management functions.
 */
const useBoolean = (
  initialValue = false,
  { withToggle } = { withToggle: false }
) => {
  const [bool, setBool] = useState(initialValue);

  if (withToggle) {
    return [
      bool,
      () => setBool(!bool),
      () => setBool(true),
      () => setBool(false),
    ];
  } else {
    return [
      bool,
      () => setBool(true),
      () => setBool(false),
      () => setBool(!bool),
    ];
  }
};

export default useBoolean;
export const useToggle = initialValue =>
  useBoolean(initialValue, { withToggle: true });
