import { JSONBuilder } from "./JSONBuilder";
import { PropsPlayerApiPayload } from '../services/superdraft-api/api-payload-types/playerProps.types';

export class Player extends JSONBuilder<PropsPlayerApiPayload> {
  static build(json: PropsPlayerApiPayload) {
    const {
      fName: firstName,
      lName: lastName,
      posAbbr: position,
      teamAbbr,
      teamId,
    } = json;

    return {
      firstName,
      lastName,
      name: `${firstName} ${lastName}`,
      position,
      teamAbbr,
      teamId,
    };
  }
}
